<script setup lang="ts">
import { QrcodeStream } from 'vue-qrcode-reader'
const log = createLog('app:qr-scan')
const { $e } = useNuxtApp()

type Emits = {
    (event: 'detect', code: string | number): void
    (event: 'error', errorMessage: string | null): void
    (event: 'update:ready', ready: boolean): void
    (event: 'update:error', error: boolean): void
    (event: 'camera-on'): void
    (event: 'camera-off'): void
}
const emit = defineEmits<Emits>()

const qrState = reactive({
    showScanConfirmation: false,
    result: null as string | null,
    valid: false,
    paused: false,
    ready: false,
    error: false,
    errorMessage: null as string | null,
})

function onCameraOn() {
    qrState.showScanConfirmation = false
    qrState.ready = true
    qrState.error = false
    qrState.errorMessage = null

    emit('camera-on')
    emit('update:error', qrState.error)
    emit('update:ready', qrState.ready)
}

function onCameraOff() {
    qrState.showScanConfirmation = true
    emit('camera-off')
}

function onError(error: DOMException) {
    qrState.showScanConfirmation = false
    qrState.valid = false
    qrState.error = true
    const errorMessage = $e(`camera${error.name}`)

    qrState.errorMessage = errorMessage

    emit('error', errorMessage)
    emit('update:error', qrState.error)
    emit('update:ready', qrState.ready)
}

async function onDetect(detectedCodes: any[]) {
    qrState.paused = true
    qrState.valid = false

    const qrCodeValue =
        detectedCodes.map(({ rawValue }) => decodeURIComponent(rawValue)).find(Boolean) ?? ''

    try {
        if (!qrCodeValue) {
            throw 'Invalid QRCode: no value'
        }
        log.debug(qrCodeValue)

        const url = new URL(qrCodeValue)

        qrState.valid = true
        qrState.result = url.pathname

        // FIXME: make this more sophisticated
        const code = url.pathname.split('/').pop()

        if (code) {
            emit('detect', code)
        }
    } catch (error) {
        qrState.result = null
    }
    await wait(500)
    qrState.paused = false
}
</script>

<template>
    <QrcodeStream
        :class="['qrcode-stream', { ['qrcode-stream--error']: qrState.error }]"
        :paused="qrState.paused"
        @detect="onDetect"
        @camera-on="onCameraOn"
        @camera-off="onCameraOff"
        @error="onError"
    >
        <div
            v-if="qrState.error"
            class="qrcode-stream__error"
        >
            <figure class="qrcode-stream__error-box">
                <img
                    alt="stop"
                    src="~/assets/icons/circle/circle-stop-48x.svg?url"
                    width="48"
                    class="qrcode-stream__error-box-icon"
                />
                <figcaption
                    v-html="qrState.errorMessage"
                    class="qrcode-stream__error-box-message"
                />
            </figure>
        </div>
        <div
            v-if="!qrState.ready && !qrState.error"
            class="qrcode-stream__loading"
        >
            <img
                alt="Checkmark"
                src="~/assets/icons/circle/circle-camera-48x.svg?url"
                width="128"
            />
        </div>
        <div
            v-if="qrState.showScanConfirmation"
            class="qrcode-stream__scan-confirmation"
        >
            <img
                v-if="qrState.valid"
                alt="Checkmark"
                src="~/assets/icons/circle/circle-check-48x.svg?url"
                width="128"
            />
            <img
                v-else
                alt="error"
                src="~/assets/icons/circle/circle-stop-48x.svg?url"
                width="128"
            />
        </div>
    </QrcodeStream>
</template>

<style lang="scss">
.qrcode-stream {
    display: grid;
    height: auto !important;
    max-width: 100%;

    @include v(
        (
            aspect-ratio: 4/3,
        )
    );

    &:not(.qrcode-stream--error) {
        aspect-ratio: v(aspect-ratio);
    }

    & > div {
        display: grid;
        aspect-ratio: v(aspect-ratio);
    }

    .qrcode-stream-wrapper {
        display: grid;
        width: 100%;
        aspect-ratio: v(aspect-ratio);
    }

    video,
    canvas {
        position: absolute;
    }

    &--error {
        width: 100%;
        height: auto !important;
        video,
        canvas {
            display: none;
        }
        & > div {
            position: static !important;
        }
    }

    &-camera {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
        width: 100%;
    }

    &-overlay {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
        display: grid;
    }

    &__loading {
        display: grid;
        aspect-ratio: v(aspect-ratio);
        height: auto;
        width: 100%;
        background-color: #000;
        place-content: center;
    }

    &__error {
        display: grid;
        aspect-ratio: v(aspect-ratio);
        background-color: #000;
        place-content: center;
        place-items: center;

        color: v(color-text-light);
    }

    &__error-box {
        background-color: v(rot-20);
        border-radius: 3px;
        border: 1px solid v(rot-80);
        display: flex;
        flex-flow: column;
        gap: v(s-12);
        padding: v(s-16);
        margin-left: 24px;
        margin-right: 24px;

        &-icon {
            align-self: center;
        }

        &-message {
            color: v(color-text);
        }
    }

    &__scan-confirmation {
        background-color: rgba(0, 0, 0, 0.5);
        display: grid;
        place-content: center;
    }
}
</style>

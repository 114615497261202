<script setup lang="ts">
import { get } from 'lodash'
import { ManualCodeInputSchema } from '~/utils/schemas/Forms'

const { $headerBackTo, $qrHuntRouter, $e } = useNuxtApp()
const log = createLog('app:qrhunt:scan')

$headerBackTo({ routeType: 'qrhunt', name: 'detail' } as QRHuntRoute)

const qrHuntStore = useQRHuntStore()
const contentStore = useContentStore()

const stepName = computed(() => get(contentStore, 'getRunningCheckpoint.name'))
const stepDescription = computed(() => get(contentStore, 'getRunningCheckpoint.description'))

const isBuisy = inject('page-loading', ref(false))
const scannerHasError = ref(false)
const scannerReady = ref(false)
const scannerErrorMessage = ref<string | null>(null)
const manualCode = ref()
const isValidManualCode = ref(false)

function onDetect(code: string | number) {
    isBuisy.value = true
    qrHuntStore.actionReedemQRCode(Number(code))
}

function onError(error: string | null) {
    scannerErrorMessage.value = error
}

function reedeemCode() {
    const result = ManualCodeInputSchema.safeParse(manualCode.value)
    if (!result.success) {
        log.error(result.error)
    }

    qrHuntStore.actionReedemQRCode(result!.data)
}

function validateInput(event: Event) {
    const inputElement = event.target as HTMLInputElement
    const result = ManualCodeInputSchema.safeParse(inputElement.value)
    isValidManualCode.value = result.success
    inputElement.setCustomValidity(result.success ? '' : result.error.message)
}

onWSMessage<ContentResponse>(ContentResponseSchema, (data) => {
    isBuisy.value = false
    $qrHuntRouter.push('content')
})

onWSMessage<WsError>(ErrorSchema, (data) => {
    const errorMessage = $e(data.content.errorType)
    log.error(errorMessage)
    isBuisy.value = false
    $qrHuntRouter.push('scan-failure')
})
</script>

<template>
    <div class="page-route-scan">
        <QrCodeScanner
            class="page-route-scan__scanner"
            @detect="onDetect"
            @error="onError"
            @update:error="(value) => (scannerHasError = value)"
            @update:ready="(value) => (scannerReady = value)"
        />

        <RichText
            class="page-route-scan__text rich-text--compact"
            :text="
                scannerHasError
                    ? $t('page.routeScan.manualText', {
                          '{step.name}': stepName,
                          '{step.description}': stepDescription,
                      })
                    : $t('page.routeScan.introText', {
                          '{step.name}': stepName,
                          '{step.description}': stepDescription,
                      })
            "
        />

        <ButtonGroup class="page-route-scan__buttons">
            <template v-if="scannerHasError">
                <Input
                    :label="$t('page.routeScan.manualInput.label')"
                    :placeholder="$t('page.routeScan.manualInput.placeholder')"
                    v-model:value="manualCode"
                    minlength="4"
                    maxlength="4"
                    name="code"
                    type="text"
                    @input="validateInput"
                />
                <AppButton
                    fluid
                    @click="reedeemCode"
                    :disabled="!isValidManualCode"
                >
                    {{ $t('page.routeScan.buttons.submit') }}
                </AppButton>
            </template>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-route-scan {
    height: 100%;
    display: grid;
    grid-template-rows: auto 32px auto 30px auto;
    grid-template-areas:
        'scanner'
        '.      '
        'text   '
        '.      '
        'buttons';
    align-content: start;

    &__scanner {
        grid-area: scanner;
    }

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>

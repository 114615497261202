<script setup lang="ts">
import { get } from 'lodash'
const log = createLog('app:input')

type PropTypes = {
    label?: string
    value: string | number
}

withDefaults(defineProps<PropTypes>(), {
    label: undefined,
})

const isValid = ref<boolean>(true)

export type Emits = {
    (event: 'update:value', payload: string | number | null): void
    (event: 'input', payload: Event): void
}

const emit = defineEmits<Emits>()

function onInput(event: Event) {
    emit('update:value', get(event, 'target.value', null))
    emit('input', event)

    const inputElement = event.target as HTMLInputElement
    isValid.value = !!inputElement.validity.valid
}
</script>

<template>
    <div class="input-field">
        <label
            v-if="label"
            class="input-field__label"
        >
            {{ label }}
        </label>
        <div class="input-field__input-wrapper">
            <input
                class="input-field__input"
                :value="value"
                @input="onInput"
                v-bind="$attrs"
                inputmode="decimal"
            />
            <Icon
                class="input-field__validation-icon"
                :name="isValid ? 'true-18x' : 'false-16x'"
            />
        </div>
    </div>
</template>

<style lang="scss">
.input-field {
    display: inline-flex;
    flex-flow: column;
    width: 100%;
    gap: v(s-10);

    &__input-wrapper {
        position: relative;
    }

    &__input {
        width: 100%;
        border-radius: 8px;
        background: hsla(#{v(wild-sand-hsl)}, 0.6);
        color: hsla(#{v(heavy-metal-hsl)}, 0.4);
        font: v(font-copy--big);
        line-height: 1.2;
        padding: v(s-12);
        min-height: 48px;
        border-width: 1px;
        border-style: solid;
        border-color: hsla(#{v(wild-sand-hsl)}, 0.6);

        &:valid {
            border-color: v(petrol);
        }

        &:invalid {
            border-color: v(rot);
        }
    }

    &__validation-icon {
        position: absolute;
        right: 12px;
        top: 15px;
        display: grid;
        place-items: center;
    }
}
</style>
